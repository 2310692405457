/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Sidebar from './Nav/Sidebar'
import "./layout.css"
import styled from 'styled-components'
import SimpleReactLightbox from "simple-react-lightbox"

const Layout = ({ children }) => {


  return (
    <>
      <Sidebar /><SimpleReactLightbox>
        <Main>
          {children}
        </Main>   </SimpleReactLightbox>

    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
const Main = styled.main`
 margin-left: 300px;
/*   padding: 1px 16px;
 */  height: 100%;

  @media (max-width:700px){
    margin-left: 0;
  }
`