import React, { useState } from 'react'
import styled from 'styled-components'
import links from '../constants/links'
import { Link } from "gatsby"
import img from '../../images/logo.svg'
import setas from '../../images/setasleft.svg'
import { FaBars } from "react-icons/fa";

import { FaBehance, FaInstagram } from 'react-icons/fa';
const Sidebar = () => {
  const [isOpen, setNav] = useState(false)

  const toggleNav = () => {

    setNav(isOpen => !isOpen)
  }
  console.log(isOpen)
  return (
    <Bar>
      <div className="twoimportant">
        <img src={img} alt="yukuri" className="logo" />
        <MenuButton onClick={toggleNav} />
      </div>
      <img src={img} alt="yukuri" className="logo2" />

      <Unordered isOpen={isOpen}>
        {links.map((item, index) => {
          return (

            <A key={index} >
              <Link fade to={item.path} className="nav-link">
                {item.text}
              </Link>
            </A>
          )
        })}</Unordered>
      <Icons> <a href="https://www.behance.net/imyukuri" target="_blank" aria-label="behance" rel="noreferrer">
        <FaBehance /> </a><a href="https://www.instagram.com/imyukuri/" rel="noreferrer" target="_blank" aria-label="instagram"><FaInstagram /></a></Icons>
      <img src={setas} alt="setas" style={{ width: 150 }} className="setas-left" />
    </Bar >
  )
}

export default Sidebar

const Bar = styled.ul`
  font-family: 'Bebas Neue', cursive;
display: flex;
flex-direction: column;
align-items: center;
position: relative;


 margin: 0;
  padding: 0;
  width: 300px;
  background-color: #282727;
  position: fixed;
  height: 100%;
  overflow: auto;
  text-transform: uppercase;
  @media (min-width:700px){
  .logo2 {
    margin-top:50px;
    width: 250}
  .setas-left{
    position: absolute;
    bottom: 0px;
    left: 10px;
  }
  li{
    margin: 10px;

  }
}

  @media(max-width: 700px) {
  flex-direction: row;
    width: 100%;
    height: auto;
    position: relative;
   align-items: center;
    .logo2{
      width:80px;
    }
  li a{
    font-size:1.2rem;
margin: 0 5px ;
  }
  .setas-left{display: none;}}
  @media(max-width: 447px){
    flex-direction: column;
.twoimportant{
  width: 100%;
  display: flex;
justify-content: space-between;
align-items: center;
}
}
@media(max-width: 447px ){
    width: 100%;
    height: auto;
    position: relative;
   align-items: center;
    .logo{
      width:80px;
    }
    .logo2{
      display: none;
    }
  li a{
    font-size:1.2rem;
margin: 0 5px ;
  }
}
@media(min-width: 447px ){
  .twoimportant{
    display: none;
  }
}
`
const A = styled.li`
a {display: block;
  color:  #C2A2C8;
  text-decoration: none;
  font-size: 36px;
  text-align: center;
  &:hover{
    color: #9ED8EB;
    background: #9ED8EB;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: #9ED8EB;}
}

  a:active {
    background-color: #9ED8EB;
  color: white;
  }
  a:hover:not(.active) {
  background-color: #555;
  color: white;
}


`
const Icons = styled.div`
margin-top: 25px;
display: flex;
color: #C2A2C8;
justify-content: space-evenly;
width: 100px;
font-size: 1.8rem;
a{
  color:#C2A2C8;
}
a:hover{
color:  #9ED8EB;
}

@media(max-width:700px){
display: none;
}
`
const MenuButton = styled(FaBars)`
font-size: 2rem;
margin-right:15px;
color:#C2A2C8;
@media(min-width: 447px){
  display: none;

}

`
const Unordered = styled.ul`
@media(max-width: 447px ){

display: none;
 display: ${props => (props.isOpen ? "flex" : "none")};

flex-direction: column;
align-items: center;
width: 100%;
margin: 0 auto;
li{
  margin-bottom: 5px;
}
}
@media(min-width: 447px ){
display: flex;
flex-direction: row;
}
@media(min-width: 700px){
  flex-direction: column;
}
`